.topWord {
  font-weight: bold;
  font-size: 0.8em;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
}

.topWordNight {
  font-weight: bold;
  font-size: 0.8em;
}

.otherWords {
  font-size: 0.2em;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
}
.otherWordsNight {
  font-size: 0.2em;
}

.clusterStyle {
  margin-left: 0.2em;
}

.textStyle {
  font-size: 0.8em;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
}

.textMiddle {
  width: 10em;
  overflow: hidden;
  line-height: 1.1em;
  height: 2em;
}

.textDetail1 {
  width: 10em;
  overflow: hidden;
  line-height: 1.1em;
  height: 2em;
}
.textDetail2 {
  width: 15em;
  overflow: hidden;
  line-height: 1.1em;
  height: 2em;
}

.textDetail3 {
  width: 15em;
  overflow: hidden;
  line-height: 1.02em;
  height: 3em;
}

.textStyle:hover {
  cursor: pointer;
}

.containerStyle {
  display: flex;
  transform: translate(-50%, -50%);
  position: relative;
}

.containerStyle:hover {
  color: #2196f3;
  cursor: pointer;
}

.sizeStyle {
  display: inline-block;
  font-size: 0.7em;
  color: white;
  font-weight: bold;
  padding: 3px;
  border-radius: 0.5em;
  border-color: #fff;
  border-width: 2px;
  border-style: solid;
  opacity: 0.8;
  margin-bottom: 0.2em;
  vertical-align: middle;
}
